.Home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--color-background);
    color: var(--color-white);
}

/* HEADER */
header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin-bottom: 2rem;
}

/* MAIN */
main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}


/* CHAT US */
.chatUs {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    background: var(--color-primary);
    color: var(--color-white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;    
    transition: all 0.3s ease;
    z-index: 10;
    box-shadow: -13px 7px 14px 0px rgba(0, 0, 0,0.5);
}


/* FOOTER */
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin-top: auto;
    margin-bottom: 1rem;
}