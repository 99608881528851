@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --color-primary: #00205B;
    --color-primary-hover: #625f944d;
    --color-secondary: #E81936;
    --color-secondary-hover: #1b1e20;
    --color-danger: #ff7782;
    --color-success: #41f1b6;
    --color-warning: #ffbb55;
    --color-white: #fff;
    --color-info-dark: #000;
    --color-info-light: #dce1eb;
    --color-dark: #363949;
    --color-light: rgba(132, 139, 200, 0.18);
    --color-primary-variant: #111e88;
    --color-dark-variant: #677483;
    --color-background: #eeeef7;
    --color-dropdown-white: #b9c6df; 
    --color-dropdown-dark: #dfb9b9; 
    --card-border-radius: 1rem;
    --border-radius-1: 0.4rem;
    --border-radius-2: 0.8rem;
    --border-radius-3: 1.2rem;

    --card-padding: 1.8rem;
    --padding-1: 1.2rem;

    --box-shadow: 0 2rem 3rem var(--color-light);

    --speed: 300ms;
    --speed-fast: 150ms;
}

/* --------------------- Dark Theme Variables */
.dark-theme-variables {
    --color-background: #181a1e;
    --color-white: #202528;
    --color-dark: #edeffd;
    --color-dark-variant: #a3bdcc;
    --color-light: rgba(0,0,0,0.4);
    --box-shadow: 0 2rem 3rem var(--color-light);
    --color-primary:#E81936;
    --color-secondary: #00205B;
    --color-primary-hover: #495257;
    --color-secondary-hover:#625f944d;
    --color-info-light: var(--color-info-dark);
    --color-dropdown-white: var(--color-dropdown-dark);
    --color-info-dark:#dce1eb;  
}
a {
    color: var(--color-dark)
}
h1 {
    font-weight: 800;
    font-size: 1.8rem;
}

h2 {
    font-size: 1.4rem;
}

h3 {
    font-size: 0.87rem;
}

h4 {
    font-size: 0.8rem;
}

h5 {
    font-size: 0.77rem;
}

small {
    font-size: 0.75rem;
}


.text-muted {
    color: var(--color-primary) !important;
    opacity: 0.5;
    font-weight: 600;
}

p {
    color: var(--color-dark-variant)
}

b {
    color: var(--color-dark);
}

.primary {
    color: var(--color-primary);
}

.secondary {
    color: var(--color-secondary);
}

.danger {
    color: var(--color-danger);
}

.warning {
    color: var(--color-warning);
}

.success {
    color: var(--color-success);
}