.chatus {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width:20rem;
    height: 40rem;
    padding: 0.5rem;
    background: var(--color-white);
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;   
    transition: all 0.3s ease;
    z-index: 11;
    box-shadow: -13px 7px 14px 0px rgba(0, 0, 0,0.5);
  }
  .chatus-header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    *align-items: center;
    padding: 0.5rem;
  }
  .chatus-header div:last-child span:hover{
    color: var(--color-danger);
  }
  .chatus-header h2{
    font-size: 1.2rem;
  }
  .chatus-header .close{
    cursor: pointer;
  }

  /* General message styles */
.chatus-message {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background: var(--color-primary-hover);
  border-radius: var(--border-radius-1);
  max-width: 70%;
  word-wrap: break-word;
  text-decoration: wrap;
}
  /* Client message styles */
.chatus-message-client {
  background: var(--color-primary);
  color: var(--color-white);
}

/* Admin message styles */
.chatus-message-admin {
  background: var(--color-warning);
  color: var(--color-white);
}
  
  .chatus-window {
    width: 98%;
    max-width: 600px;
    height: 50%;
    border: 1px solid #ccc;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    margin-bottom: 1rem;
    padding: 1rem;
  }
  .chatus-inpu-box{
    display:flex;
    width: 100%;
    height: 5rem;
    align-items: center;
    *border: solid 1px var(--color-primary);
    *background: red;
    *justify-content: space-between;
    *align-items: center;
  }
  
  .chatus-inpu-box input {
    width: 100%;
    max-width: 600px;
    height: 5rem;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    *border: solid 1px var(--color-primary);
    *background: var(--colro-info-dark);
    color: var(--color-dark)
  }

  .chatus-inpu-box button {}
  .chatus-inpu-box button:hover {
    background: var(--color-primary-hover);
  }
  
  button {
    padding: 0.5rem 1rem;
  } 